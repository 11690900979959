import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Eye Icons Added
import { ImSpinner7 } from "react-icons/im";

const Keys = () => {
  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [paymentApiLink, setPaymentApiLink] = useState("");
  const [loading, setLoading] = useState(false);

  // States to toggle visibility
  const [showPublicKey, setShowPublicKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [showPaymentApiLink, setShowPaymentApiLink] = useState(false);
  const [skelitonLoading, setSkelitonLoading] = useState(true);

  const accesstoken = getAccesstoken();

  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setPublicKey(userData?.user?.public_key || "");
      setSecretKey(userData?.user?.secret_key || "");
      setPaymentApiLink(userData?.user?.payment_api_link || "");
      setSkelitonLoading(false);
    };

    fetchUser();
  }, [accesstoken]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      public_key: publicKey,
      secret_key: secretKey,
      payment_api_link: paymentApiLink,
    };

    try {
      await axios.put(
        `${import.meta.env.VITE_API_URL}/auth/update-user`,
        body,
        {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        }
      );

      //get user fron localstorage and update keys and again set it to localstorage
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      user.public_key = publicKey;
      user.secret_key = secretKey;
      user.payment_api_link = paymentApiLink;
      localStorage.setItem("user", JSON.stringify(user));

      setLoading(false);
      
      toast.success("Uploaded Successfully");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const FormSkeleton = () => (
    <div className="flex flex-col gap-4 mt-10">
      {/* Public Key Field */}
      <div className="flex flex-col gap-2">
        <div className="h-5 w-24 bg-gray-300 rounded animate-pulse"></div>
        <div className="relative">
          <div className="h-12 bg-gray-300 rounded-md w-full animate-pulse"></div>
          <div className="absolute right-3 top-3 h-5 w-5 bg-gray-400 rounded animate-pulse"></div>
        </div>
      </div>
  
      {/* Secret Key Field */}
      <div className="flex flex-col gap-2">
        <div className="h-5 w-24 bg-gray-300 rounded animate-pulse"></div>
        <div className="relative">
          <div className="h-12 bg-gray-300 rounded-md w-full animate-pulse"></div>
          <div className="absolute right-3 top-3 h-5 w-5 bg-gray-400 rounded animate-pulse"></div>
        </div>
      </div>
  
      {/* Payment API Link Field */}
      <div className="flex flex-col gap-2">
        <div className="h-5 w-36 bg-gray-300 rounded animate-pulse"></div>
        <div className="relative">
          <div className="h-12 bg-gray-300 rounded-md w-full animate-pulse"></div>
          <div className="absolute right-3 top-3 h-5 w-5 bg-gray-400 rounded animate-pulse"></div>
        </div>
      </div>
  
      {/* Submit Button */}
      <div className="h-12 bg-gray-400 rounded-md w-full animate-pulse"></div>
    </div>
  );

  return (
    <div className=" custom_container py-20 flex items-center justify-center">
      <div className="bg-white rounded-lg border p-8 md:w-2/3 lg:w-1/2 w-full">
        <h1 className="text-2xl md:text-4xl font-bold text-center text-gray-800">
          Upload Your Keys
        </h1>
        <p className="w-full text-center mx-auto mt-3 text-base text-gray-600">
          You can upload your keys here. If you don't have any keys, please
          collect them from the main website. Because without keys, you can't
          withdraw or deposit money.
        </p>

        {
          skelitonLoading ? (
            <FormSkeleton />
          )
          :
          (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-10">
          {/* Public Key Field */}
          <div className="flex flex-col gap-2 relative">
            <label className="text-gray-700 font-semibold" htmlFor="publicKey">
              Public Key
            </label>
            <div className="relative">
              <input
                type={showPublicKey ? "text" : "password"}
                id="publicKey"
                placeholder="Public Key"
                value={publicKey}
                onChange={(e) => setPublicKey(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button
                type="button"
                onClick={() => setShowPublicKey(!showPublicKey)}
                className="absolute right-3 top-3 text-gray-500 hover:text-gray-700"
              >
                {showPublicKey ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </button>
            </div>
          </div>

          {/* Secret Key Field */}
          <div className="flex flex-col gap-2 relative">
            <label className="text-gray-700 font-semibold" htmlFor="secretKey">
              Secret Key
            </label>
            <div className="relative">
              <input
                type={showSecretKey ? "text" : "password"}
                id="secretKey"
                placeholder="Secret Key"
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button
                type="button"
                onClick={() => setShowSecretKey(!showSecretKey)}
                className="absolute right-3 top-3 text-gray-500 hover:text-gray-700"
              >
                {showSecretKey ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </button>
            </div>
          </div>

          {/* Payment API Link Field */}
          <div className="flex flex-col gap-2 relative">
            <label className="text-gray-700 font-semibold" htmlFor="paymentApiLink">
              Payment API Link
            </label>
            <div className="relative">
              <input
                type={showPaymentApiLink ? "text" : "password"}
                id="paymentApiLink"
                placeholder="Enter Payment API Link"
                value={paymentApiLink}
                onChange={(e) => setPaymentApiLink(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button
                type="button"
                onClick={() => setShowPaymentApiLink(!showPaymentApiLink)}
                className="absolute right-3 top-3 text-gray-500 hover:text-gray-700"
              >
                {showPaymentApiLink ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="px-4 py-3 bg-blue-500 rounded-md text-white font-semibold hover:bg-blue-600 transition duration-300"
          >
            {loading ? <span className="flex items-center gap-2 justify-center"> <ImSpinner7 className="animate-spin" size={20}/> Updating...</span>: "Update"}
          </button>
        </form>
          )
        }
        
      </div>
    </div>
  );
};

export default Keys;
