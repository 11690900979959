import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UserType } from "../../Types/UserType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";
import { FiChevronDown, FiLogOut } from "react-icons/fi";

const Header = () => {
  const [user, setUser] = useState<UserType | null>(null);
  const accesstoken = getAccesstoken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    setUser(null);
    setShowMenu(false);
    navigate("/sign-in");
    setShowMenu(false);
  };
  console.log(location.pathname);

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(()=> {
    setShowMenu(false);
  }, [])

  return (
    <header className=" text-black  border-b border-gray-200">
      <div className="custom_container flex flex-col md:flex-row font-semibold justify-between items-center p-4">
        <div className="text-2xl font-bold text-center">
          <Link to="/" className="flex flex-col items-center">
            <img src="/logo.png" alt="" className="h-10" />
            {/* <span className="text-sm font-mono mt-1">Karentpay Checker</span> */}
          </Link>
        </div>
        <nav className="mt-5 md:mt-0">
          <ul className="flex flex-wrap justify-center items-center gap-3 relative">
            {/* Navigation Links */}
            <li
              className={`  rounded-lg text-black transition-all duration-300 ${
                location.pathname === "/keys"
                  ? "text-blue-600"
                  : "text-black hover:text-blue-700 active:text-blue-800"
              }`}
            >
              <Link className="p-2" to="/keys">Keys</Link>
            </li>
            <li
              className={`  rounded-lg text-black transition-all duration-300 ${
                location.pathname === "/withdraws"
                  ? "text-blue-600"
                  : "text-black hover:text-blue-700 active:text-blue-800"
              }`}
            >
              <Link className="p-2" to="/withdraws">Withdraw</Link>
            </li>
            <li
              className={`  rounded-lg text-black transition-all duration-300 ${
                location.pathname === "/deposits-direct"
                  ? "text-blue-600"
                  : "text-black hover:text-blue-700 active:text-blue-800"
              }`}
            >
              <Link className="p-2" to="/deposits-direct">Direct Deposit</Link>
            </li>
            <li
              className={`  rounded-lg  text-black transition-all duration-300 ${
                location.pathname === "/deposits"
                  ? "text-blue-600"
                  : "text-black hover:text-blue-700 active:text-blue-800"
              }`}
            >
              <Link className="p-2" to="/deposits">Deposit</Link>
            </li>

            {/* User Profile Dropdown (Last Item) */}
            {user?.id ? (
              <li className="relative" ref={menuRef}>
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  onMouseEnter={() => setShowMenu(true)}
                  className="flex items-center capitalize gap-2 border border-blue-400  text-black px-3 py-2 rounded-lg hover:text-gray-700 transition-all duration-300"
                >
                  {user.name?.length > 18 ? user.name.slice(0, 18) + "..." : user.name} <FiChevronDown />
                </button>

                {showMenu && (
                  <div>
                    <div
                      // onMouseLeave={() => setShowMenu(false)}
                      className="absolute z-[99] -right-7 sm:right-0 bg-white  mt-2 w-52 text-black shadow-lg rounded-lg p-3 border border-gray-200"
                    >
                      <p className="text-gray-700 ">
                        {user?.email
                          ? user.email.split("@")[0].length > 10
                            ? user.email.split("@")[0].slice(0, 10) +
                              "..." +
                              "@" +
                              user.email.split("@")[1]
                            : user.email
                          : ""}
                      </p>

                      <button
                        onClick={logout}
                        className="mt-2 w-full flex items-center justify-center gap-2  bg-gray-100 px-3 py-2 rounded-lg hover:text-blue-500 active:text-blue-600  transition duration-200"
                      >
                        <FiLogOut /> Sign Out
                      </button>
                    </div>
                  </div>
                )}
              </li>
            ) : (
              <li className="gap-3 flex items-center">
                 <Link
                  to="/sign-in"
                  className={`text-white ${location.pathname === "/sign-in"  ? "hidden" : "block"} bg-blue-500 px-3 py-2 rounded-md hover:bg-blue-600 active:bg-pink-700 transition-all duration-300`}
                >
                  Sign In 
                </Link>

                <Link
                  to="/sign-up"
                  className={`text-white ${location.pathname === "/sign-up"  ? "hidden" : "block"} bg-blue-500 px-3 py-2 rounded-md hover:bg-blue-600 active:bg-pink-700 transition-all duration-300`}
                >
                  Register
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
