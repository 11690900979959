import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Deposit from "../pages/Deposit/Deposit";
import Home from "../pages/Home/Home";
import Keys from "../pages/Keys/Keys";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignIn/SignUp";
import Withdraw from "../pages/Withdraw/Withdraw";
import DepositDirect from "../pages/Deposit/DepositDirect";
import ProtectedRout from "./ProtectedRout";
import AuthProducted from "./AuthProducted";



export const router = createBrowserRouter(
  [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <ProtectedRout><Home /></ProtectedRout> ,
      },
      {
        path: "/sign-in",
        element: <AuthProducted><SignIn /></AuthProducted> ,
      },
      {
        path: "/sign-up",
        element: <AuthProducted><SignUp /></AuthProducted> ,
      },
      {
        path: "/dashboard",
        element: <ProtectedRout><Dashboard /></ProtectedRout> ,
      },
      {
        path: "/keys",
        element: <ProtectedRout><Keys /></ProtectedRout> ,
      },
      {
        path: "/withdraws",
        element: <ProtectedRout><Withdraw /></ProtectedRout> ,
      },
      {
        path: "/deposits",
        element: <ProtectedRout><Deposit /></ProtectedRout> ,
      },
      {
        path: "/deposits-direct",
        element: <ProtectedRout><DepositDirect /></ProtectedRout> ,
      },
    ],
  },
]);
