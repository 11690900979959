import { useEffect, useState } from "react";
import moment from "moment";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

interface ActivityDetailsProps {
  setModal: (show: boolean) => void;
  modal: {
    callbacks?: string[];
    createdAt?: string;
    status?: string;
    amount?: number;
    currency?: string;
    payment_method?: string;
    withdraw_number?: string;
  } | null;
}

function ActivityDetails({ setModal, modal }: ActivityDetailsProps) {
  const [parsedCallbacks, setParsedCallbacks] = useState<any[]>([]);

  useEffect(() => {
    if (modal?.callbacks) {
      parseCallbacks(modal.callbacks);
    }
  }, [modal]);

  if (!modal) return null;

  const parseCallbacks = (callbacks: any[]) => {
    let parsedData = [];
    if (Array.isArray(callbacks)) {
      parsedData = callbacks.map((item) => {
        try {
          return JSON.parse(item);
        } catch (error) {
          console.error("Error parsing callback:", error);
          return {};
        }
      });
    }
    setParsedCallbacks(parsedData);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white  rounded-lg shadow-lg w-[90%] md:w-6/12 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4 border-b p-6 relative bg-gray-50">
          <h2 className="text-2xl font-semibold text-gray-800  ">
            Activity Details
          </h2>
          <button
            onClick={() => setModal(false)}
            className="absolute top-2 right-2 bg-red-500 hover:bg-red-700 w-8 h-8 flex items-center justify-center text-white text-xl font-bold rounded-full"
          >
            X
          </button>
        </div>
        <div className="space-y-4 p-6">
          <div className="flex justify-between border-b pb-2">
            <strong>Date And Time :</strong>
            <span>
              {modal.createdAt && (
                <>
                  <span>{moment(modal.createdAt).format("DD MMM YYYY")}</span>
                  <span className="mx-2">|</span>
                  <span>{moment(modal.createdAt).format("h:mm a")}</span>
                  <div className="text-gray-500 text-sm mt-1 text-right">
                    <span className="text-blue-600">
                      ( {moment(modal.createdAt).fromNow()} )
                    </span>
                  </div>
                </>
              )}
            </span>
          </div>
          <div className="flex justify-between border-b pb-2">
            <strong>Status :</strong>
            <span className="text-blue-600">{modal.status}</span>
          </div>
          <div className="flex justify-between border-b pb-2">
            <strong>Amount :</strong>
            <span>
              {modal.amount} {modal.currency}
            </span>
          </div>
          {modal?.payment_method && (
            <div className="flex justify-between border-b pb-2">
              <strong>Payment Method :</strong>
              <span>{modal?.payment_method}</span>
            </div>
          )}

          {modal.withdraw_number && (
            <div className="flex justify-between border-b pb-2">
              <strong>Withdraw Number :</strong>
              <span>{modal.withdraw_number}</span>
            </div>
          )}

          {parsedCallbacks.length > 0 && (
            <div className="mt-4">
              <h3 className="font-bold mb-2">Callbacks:</h3>
              <div className="bg-gray-100 p-3 rounded text-sm overflow-auto">
                {parsedCallbacks.map((callback, index) => (
                  <div key={index} className="mb-4 border p-2 rounded">
                    <JsonView src={callback} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ActivityDetails;
