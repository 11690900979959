
import { Navigate } from "react-router-dom";

const AuthProducted = ({ children }: { children: JSX.Element }) => {
    const access_token = localStorage.getItem("access_token");

    if (!access_token) {
        return children;
    }

    return <Navigate to="/" />;
};

export default AuthProducted;
