import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { DepositType } from "../../Types/DepositeType";
import { UserType } from "../../Types/UserType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import ActivityDetails from "../Withdraw/ActivityDetails";

const DepositDirect = () => {
  const url = import.meta.env.VITE_API_URL;

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const status = searchParams.get("status");
  const reference = searchParams.get("refarence");
  const [deposits, setDeposits] = useState<DepositType[]>([]); // Specify type here
  const [depositLoading, setDepositLoading] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [depositMethode, setdepositMethode] = useState("");
  // const [deposiNumber, setDeposiNumber] = useState("");
  const [user, setUser] = useState<UserType | null>(null);
  const [errors, setErrors] = useState({
    method: "",
    amount: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  // get user
  const accesstoken = getAccesstoken();
  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  useEffect(() => {
    fetchDeposit();
  }, [user, url]);

  const fetchDeposit = async () => {
    if (!user) {
      return;
    }
    setIsLoading(true);
    try {
      const getDeposit = await axios.get(
        `${url}/transaction/deposit/${user.id}`
      );
      setDeposits(getDeposit?.data?.withdrawsWithCallbacks);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!status || !reference || !user) {
      return;
    }

    if (status === "success") {
      axios.put(`${url}/transaction/deposit/${user?.id}/${reference}`, {
        status: "success",
      });
    }
  }, [status, reference, user, url]);

  // const handleDeleteDeposit = async (deposit: DepositType) => {
  //   try {
  //     await axios.delete(`${url}/transaction/deposit/delete/${deposit?.id}`);
  //     setDeposits((prev) => prev.filter((d) => d?.id !== deposit?.id));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeposit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({ method: "", amount: "" });

    // Validate fields
    const newErrors = {
      method: !depositMethode ? "Payment method is required" : "",
      amount: !depositAmount ? "Deposit amount is required" : "",
    };

    if (newErrors.method || newErrors.amount) {
      setErrors(newErrors);
      return;
    }
    if (Number(depositAmount) < 1) {
      toast.error("Deposit amount must be greater than 1");
      return;
    }

    try {
      setDepositLoading(true);

      if (!user?.secret_key || !user?.payment_api_link) {
        toast.error("Please update your payment information first.");
        navigate("/keys");
      }

      const response = await axios.post(`${url}/transaction/deposit-direct`, {
        amount: Number(depositAmount),
        userId: user?.id,
        method: depositMethode,
        payment_api_link: user?.payment_api_link,
        secret_key: user?.secret_key,
      });

      if (response?.data?.paymentResponse?.url) {
        toast.success("Deposit request created successfully");
        setDepositAmount("");
        fetchDeposit();

        // console.log(response?.data);

        const paymentUrl = response?.data?.paymentResponse?.url;
        // console.log(paymentUrl);
        if (paymentUrl) {
          window.location.href = paymentUrl;
        }

        setDepositLoading(false);
      } else {
        toast.error("Deposit failed");
        setDepositLoading(false);
      }

      // console.log(response?.data?.paymentResponse);
    } catch (error: any) {
      console.log(error?.response?.data?.error);
      toast.error(
        error?.response?.data?.error ||
          error?.message ||
          "Invalid withdrawal amount or insufficient balance."
      );
      setDepositLoading(false);
    }
  };

  const TableSkeleton = () => (
    <tbody>
      {[1, 2, 3, 4, 5].map((index) => (
        <tr
          key={index}
          className="border-b border-opacity-20 border-gray-700 bg-gray-900"
        >
          {[1, 2, 3, 4, 5, 6, 7].map((cellIndex) => (
            <td key={cellIndex} className="p-3">
              <div className="h-4 bg-gray-700 rounded animate-pulse"></div>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  const filteredDeposits = deposits.filter(
    (deposit) => deposit?.method?.length > 0
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDeposits = filteredDeposits.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredDeposits.length / itemsPerPage);

  const Pagination = () => {
    return (
      <div className="flex justify-center gap-2 mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 bg-gray-700 rounded-md disabled:opacity-50 text-white"
        >
          Previous
        </button>
        <span className="px-3 py-1 text-black">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="px-3 py-1 bg-gray-700 rounded-md disabled:opacity-50 text-white"
        >
          Next
        </button>
      </div>
    );
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy to clipboard!");
      });
  };

  const [modal, setModal] = useState<DepositType | null>(null);

  return (
    <section className=" min-h-[85vh] custom_container py-5 pb-7">
      {/* <h1 className="text-3xl font-bold text-left mb-5">Direct Deposits</h1> */}

      {modal && (
        <ActivityDetails
          modal={{ ...modal, createdAt: modal?.createdAt?.toString() }}
          setModal={() => setModal(null)}
        />
      )}
      <div className="w-full lg:w-2/4 mt-5">
        <div className="flex flex-col gap-2">
          <form onSubmit={handleDeposit} className="flex flex-col gap-2">
            <select
              name="payment_method"
              id="payment_method"
              className={`py-2 px-3 border rounded-md ${
                errors.method ? "border-red-500" : "border-gray-700"
              }`}
              value={depositMethode}
              onChange={(e) => {
                errors.method = "";
                setdepositMethode(e.target.value);
              }}
            >
              <option value="">Payment Method</option>
              <option value="bkash">Bkash</option>
              <option value="nagad">Nagad</option>
            </select>
            {errors.method && (
              <p className="text-red-500 text-sm mt-1">{errors.method}</p>
            )}

            <input
              type="number"
              placeholder="Deposit Amount"
              value={depositAmount}
              onChange={(e) => {
                errors.amount = "";
                setDepositAmount(e.target.value);
              }}
              className={`py-2 px-3 border rounded-md ${
                errors.amount ? "border-red-500" : "border-gray-700"
              }`}
            />
            {errors.amount && (
              <p className="text-red-500 text-sm mt-1">{errors.amount}</p>
            )}

            <button
              type="submit"
              className="px-3 py-2 bg-blue-500 hover:bg-blue-600 active:bg-blue-700 border-none rounded-md text-white flex items-center justify-center"
              disabled={depositLoading}
            >
              {depositLoading ? <Loading /> : "Deposit Direct"}
            </button>
          </form>
        </div>
      </div>

      <div className="mx-auto text-gray-100 mt-10 text-[16px]">
        <div className="overflow-x-auto rounded-md">
          <table className="min-w-full ">
            <colgroup>
              <col className="w-[20%]" />
              <col className="w-[20%]" />
              <col className="w-[16%]" />
              <col className="w-[12%]" />
              <col className="w-[12%]" />
              <col className="w-[8%]" />
              <col className="w-[12%]" />
            </colgroup>
            <thead className="bg-gray-600">
              <tr className="text-left">
                <th className="p-3  whitespace-nowrap">Date & Time</th>
                <th className="p-3  whitespace-nowrap">Payment Info</th>
                <th className="p-3  whitespace-nowrap">Payment Method</th>
                <th className="p-3  whitespace-nowrap">Amount</th>
                <th className="p-3  whitespace-nowrap">Currency</th>
                <th className="p-3  whitespace-nowrap">Status</th>
                <th className="p-3 text-center ">Action</th>
              </tr>
            </thead>
            {isLoading ? (
              <TableSkeleton />
            ) : currentDeposits && currentDeposits.length > 0 ? (
              <tbody>
                {currentDeposits.map(
                  (deposit: DepositType) =>
                    deposit?.method?.length > 0 && (
                      <tr
                        key={deposit?.id}
                        className="border-b border-opacity-20 border-gray-700 bg-gray-900 "
                      >
                        <td className="p-3 whitespace-nowrap">
                          <p>
                            {moment(deposit?.createdAt).format("MMM D, YYYY")}
                          </p>
                          <p>{moment(deposit?.createdAt).format("h:mm A")}</p>
                        </td>
                        <td className="p-3 ">
                          <span className="flex gap-1">
                            Trx:
                            <p
                              className="cursor-pointer hover:text-blue-400 transition-colors"
                              onClick={() => copyToClipboard(deposit?.trxId)}
                              title="Click to copy"
                            >
                              {" "}
                              {deposit?.trxId}
                            </p>
                          </span>

                          <span className="flex gap-1">
                            Ref:
                            <p
                              className="cursor-pointer hover:text-blue-400 transition-colors"
                              onClick={() =>
                                copyToClipboard(deposit?.reference)
                              }
                              title="Click to copy"
                            >
                              {deposit?.reference}
                            </p>
                          </span>
                        </td>
                        <td className="p-3">
                          <p className="capitalize">{deposit?.method}</p>
                        </td>
                        <td className="p-3">
                          <p>{deposit?.amount}</p>
                        </td>
                        <td className="p-3">
                          <p>{deposit?.currency}</p>
                        </td>
                        <td className="p-3">
                          <p
                            className={`text-center px-2 py-1 rounded-md  whitespace-nowrap ${
                              deposit?.status.toLowerCase().includes("pend")
                                ? "bg-yellow-500"
                                : deposit?.status
                                    .toLowerCase()
                                    .includes("success")
                                ? "bg-green-500"
                                : deposit?.status
                                    .toLowerCase()
                                    .includes("reject")
                                ? "bg-red-500"
                                : deposit?.status
                                    .toLowerCase()
                                    .includes("await")
                                ? "bg-orange-500"
                                : deposit?.status.toLowerCase().includes("fail")
                                ? "bg-red-700"
                                : "bg-blue-500"
                            }`}
                          >
                            {deposit?.status.charAt(0).toUpperCase() +
                              deposit?.status.slice(1).toLowerCase()}
                          </p>
                        </td>

                        <td className="p-3 cursor-pointer items-center h-auto">
                          <div className="flex items-center gap-2  justify-center">
                            {deposit?.callbacks?.length ? (
                              <button
                                onClick={() => setModal(deposit)}
                                className=" ml-5 bg-blue-500 rounded flex items-center justify-center p-2 cursor-pointer hover:bg-blue-600 active:bg-blue-700 opacity-95"
                              >
                                <FaEye />
                              </button>
                            ) : (
                              <button
                                className=" ml-5 bg-blue-500 rounded flex items-center justify-center p-2 cursor-not-allowed opacity-50"
                                disabled
                              >
                                <FaEye />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            ) : (
              <tbody>
                <tr className="border-b border-opacity-20 border-gray-700 bg-gray-900">
                  <td className="p-3 whitespace-nowrap" colSpan={7}>
                    <p className="text-center">No Deposits Found</p>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
      {!isLoading && filteredDeposits.length > 7 && <Pagination />}
    </section>
  );
};

export default DepositDirect;
