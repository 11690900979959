import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { DepositType } from "../../Types/DepositeType";
import { UserType } from "../../Types/UserType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import ActivityDetails from "../Withdraw/ActivityDetails";

const Deposit = () => {
  const url = import.meta.env.VITE_API_URL;

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const status = searchParams.get("status");
  const reference = searchParams.get("refarence");
  const [deposits, setDeposits] = useState<DepositType[]>([]); // Specify type here
  const [depositLoading, setDepositLoading] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [user, setUser] = useState<UserType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [depositError, setDepositError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  // Filter deposits first, then paginate
  const filteredDeposits = deposits.filter(
    (deposit) => deposit?.method == null
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDeposits = filteredDeposits.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredDeposits.length / itemsPerPage);

  // Add pagination controls component
  const Pagination = () => {
    return (
      <div className="flex justify-center gap-2 mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 bg-gray-700 rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <span className="px-3 py-1 text-black">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="px-3 py-1 bg-gray-700 rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    );
  };

  // get user
  const accesstoken = getAccesstoken();
  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  useEffect(() => {
    fetchDeposit();
  }, [user, url]);

  const fetchDeposit = async () => {
    if (!user) {
      return;
    }

    setIsLoading(true);
    try {
      const getDeposit = await axios.get(
        `${url}/transaction/deposit/${user.id}`
      );
      setDeposits(getDeposit?.data?.withdrawsWithCallbacks);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!status || !reference || !user) {
      return;
    }

    if (status === "success") {
      axios.put(`${url}/transaction/deposit/${user?.id}/${reference}`, {
        status: "success",
      });
    }
  }, [status, reference, user, url]);

  // const handleDeleteDeposit = async (deposit: DepositType) => {
  //   try {
  //     await axios.delete(`${url}/transaction/deposit/delete/${deposit?.id}`);
  //     setDeposits((prev) => prev.filter((d) => d?.id !== deposit?.id));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeposit = async (e: React.FormEvent) => {
    e.preventDefault();
    setDepositError("");

    if (!depositAmount) {
      setDepositError("Please enter a deposit amount");
      return;
    }

    try {
      setDepositLoading(true);

      if (!user?.secret_key || !user?.payment_api_link) {
        toast.error("Please update your payment information first.");
        navigate("/keys");
      }

      const response = await axios.post(`${url}/transaction/deposit`, {
        amount: Number(depositAmount),
        userId: user?.id,
        payment_api_link: user?.payment_api_link,
        secret_key: user?.secret_key,
      });

      if (response?.data) {
        toast.success("Deposit request created successfully");
        setDepositAmount("");
        fetchDeposit();

        // console.log(response?.data);

        const paymentUrl = response?.data?.paymentResponse?.data?.payment_url;
        // console.log(paymentUrl);
        if (paymentUrl) {
          window.location.href = paymentUrl;
        }

        setDepositLoading(false);
      }

      // console.log(response?.data?.paymentResponse);
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.response?.data?.error ||
          error?.message ||
          "Invalid withdrawal amount or insufficient balance."
      );
      setDepositLoading(false);
    }
  };

  const SkeletonRow = () => (
    <tr className="border-b border-opacity-20 border-gray-700 bg-gray-900">
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <td key={i} className="p-3">
          <div className="h-4 bg-gray-700 rounded animate-pulse"></div>
        </td>
      ))}
    </tr>
  );

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy to clipboard!");
      });
  };


  const [modal, setModal] = useState<DepositType | null>(null);

  return (
    <section className=" min-h-[85vh] custom_container py-5 pb-7">
      {/* <h1 className="text-3xl font-bold text-left mb-5">Deposits</h1> */}

      {modal && (
        <ActivityDetails modal={{ ...modal, createdAt: modal?.createdAt?.toString() }} setModal={() => setModal(null)} />
      )}


      <div className="w-full lg:w-2/4 mt-5">
        <div className="flex flex-col gap-2">
          <form onSubmit={handleDeposit} className="flex flex-col gap-2">
            <input
              type="number"
              placeholder="Deposit Amount"
              value={depositAmount}
              onChange={(e) => {
                setDepositAmount(e.target.value);
                setDepositError("");
              }}
              className={`py-2 px-3 border rounded-md ${
                depositError ? "border-red-500" : "border-gray-700"
              }`}
            />
            {depositError && (
              <p className="text-red-500 text-sm mt-1">{depositError}</p>
            )}
            <button
              type="submit"
              className="px-3 py-2 bg-blue-500 hover:bg-blue-600 active:bg-blue-700  border-none rounded-md text-white flex items-center justify-center"
              disabled={depositLoading}
            >
              {depositLoading ? <Loading /> : "Deposit"}
            </button>
          </form>
        </div>
      </div>

      <div className="mx-auto text-gray-100 mt-10 text-[16px]">
        <div className="overflow-x-auto rounded-md">
          <table className="min-w-full ">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col className="w-24" />
              {/* <col /> */}
            </colgroup>
            <thead className="bg-gray-600">
              <tr className="text-left ">
                <th className="p-3 whitespace-nowrap">Date & Time </th>
                <th className="p-3 whitespace-nowrap">Payment Info</th>
                <th className="p-3 whitespace-nowrap">Amount</th>
                <th className="p-3">Currency</th>
                <th className="p-3">Status</th>
                <th className="p-3 text-center">Action</th>
              </tr>
            </thead>

            {isLoading ? (
              [...Array(5)].map((_, index) => <SkeletonRow key={index} />)
            ) : currentDeposits.length > 0 ? (
              currentDeposits.map((deposit: DepositType) => (
                <tbody>
                  <tr
                    key={deposit?.id}
                    className="border-b border-opacity-20 border-gray-700 bg-gray-900 "
                  >
                    <td className="p-3">
                      <p>{moment(deposit?.createdAt).format("MMM D, YYYY")}</p>
                      <p>{moment(deposit?.createdAt).format("h:mm A")}</p>
                    </td>
                    <td className="p-3 max-w-[200px]">
                      <span className="flex gap-1">
                        Trx:
                        <p
                          className="cursor-pointer hover:text-blue-400 transition-colors"
                          onClick={() => copyToClipboard(deposit?.trxId)}
                          title="Click to copy"
                        >
                          {" "}
                          {deposit?.trxId}
                        </p>
                      </span>

                      <span className="flex gap-1">
                        Ref:
                        <p
                          className="cursor-pointer hover:text-blue-400 transition-colors"
                          onClick={() => copyToClipboard(deposit?.reference)}
                          title="Click to copy"
                        >
                          {deposit?.reference}
                        </p>
                      </span>
                    </td>
                    <td className="p-3 max-w-[100px]">
                      <p>{deposit?.amount}</p>
                    </td>
                    <td className="p-3">
                      <p>{deposit?.currency}</p>
                    </td>

                    <td className="p-3">
                      <p
                        className={`text-center px-2 py-1 rounded-md text-base ${
                          deposit?.status.toLowerCase().includes("pend")
                            ? "bg-yellow-500"
                            : deposit?.status.toLowerCase().includes("success")
                            ? "bg-green-500"
                            : deposit?.status.toLowerCase().includes("reject")
                            ? "bg-red-500"
                            : deposit?.status.toLowerCase().includes("await")
                            ? "bg-orange-500"
                            : deposit?.status.toLowerCase().includes("fail")
                            ? "bg-red-700"
                            : "bg-blue-500"
                        }`}
                      >
                        {deposit?.status.charAt(0).toUpperCase() +
                          deposit?.status.slice(1).toLowerCase()}
                      </p>
                    </td>
                    <td className="p-3 cursor-pointer items-center h-auto">
                      <div className="flex items-center gap-2  justify-center">
                        {deposit?.callbacks?.length ? (
                          <button
                            onClick={() => setModal(deposit)}
                            className=" ml-5 bg-blue-500 rounded flex items-center justify-center p-2 cursor-pointer hover:bg-blue-600 active:bg-blue-700 opacity-95"
                          >
                            <FaEye />
                          </button>
                        ) : (
                          <button
                            className=" ml-5 bg-blue-500 rounded flex items-center justify-center p-2 cursor-not-allowed opacity-50"
                            disabled
                          >
                            <FaEye />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))
            ) : (
              <tbody>
                <tr className="border-b border-opacity-20 border-gray-700 bg-gray-900">
                  <td className="p-3 whitespace-nowrap" colSpan={6}>
                    <p className="text-center">No Deposits Found</p>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {!isLoading && deposits.length > itemsPerPage && <Pagination />}
      </div>
    </section>
  );
};

export default Deposit;
