
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const access_token = localStorage.getItem("access_token");
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const pathname = window.location.pathname;

    if (!access_token) {
        return <Navigate to="/sign-in" />;
    }

    if (pathname === "/keys") {
        return children;
    }

    if (user?.public_key && user?.secret_key && user?.payment_api_link) {
        return children;
    }

    console.log("Keys not found:", user?.public_key, user?.secret_key, user?.payment_api_link);
    return <Navigate to="/keys" />;
};

export default ProtectedRoute;
