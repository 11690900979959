import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { UserType } from "../../Types/UserType";
import { WithdrawType } from "../../Types/WithdrawType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import ActivityDetails from "./ActivityDetails";

const Withdraw = () => {
  const navigate = useNavigate();
  const url = import.meta.env.VITE_API_URL;
  const [withdraws, setWithdraws] = useState<WithdrawType[]>([]);
  const [user, setUser] = useState<UserType | null>(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [withdrawNumber, setWithdrawNumber] = useState("");
  const [withdrawMethod, setWithdrawMethod] = useState("");
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const [enabled, setEnabled] = useState(false);
  const [enableSeconds, setEnableSeconds] = useState<number | null>(1000);
  const [enabledInterval, setEnabledInterval] = useState<number | null>(1);

  const [selectedWithdraw, setSelectedWithdraw] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [errors, setErrors] = useState({
    method: "",
    number: "",
    amount: "",
  });

  const [isBalanceLoading, setIsBalanceLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  // const currentWithdraws = withdraws.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const [filteredNumbers, setFilteredNumbers] = useState<string[]>([]);
  const [showNumberSuggestions, setShowNumberSuggestions] = useState(false);
  const [allPhoneNumebers, setAllPhoneNumbers] = useState<WithdrawType[]>([]);

  const Pagination = () => {
    return (
      <div className="flex justify-center gap-2 mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 bg-gray-700 rounded-md disabled:opacity-50 text-white"
        >
          Previous
        </button>
        <span className="px-3 py-1 text-black">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="px-3 py-1 bg-gray-700 rounded-md disabled:opacity-50 text-white"
        >
          Next
        </button>
      </div>
    );
  };

  const openModal = (withdraw: WithdrawType) => {
    setSelectedWithdraw(withdraw?.payment_id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedWithdraw("");
    setIsOpen(false);
  };

  // get user
  const accesstoken = getAccesstoken();
  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  useEffect(() => {
    if (!user || !selectedWithdraw) return;

    const checkPaymentStatus = async () => {
      try {
        await axios.post(
          `${url}/transaction/withdraw/success/manual/check-success`,
          {
            payment_id: selectedWithdraw,
            public_key: user?.public_key,
            payment_api_link: user?.payment_api_link,
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

    checkPaymentStatus();
  }, [selectedWithdraw, url, user]);

  const fetchBalance = async () => {
    if (!user?.secret_key) return;
    try {
      setIsBalanceLoading(true);
      const response = await axios.get(
        `${user?.payment_api_link}/user_by_secret_key`,
        {
          headers: {
            secret_key: user?.secret_key,
          },
        }
      );
      setBalance(response?.data?.data?.user?.automation_balance);
    } catch (error) {
      console.log(error);
    } finally {
      setIsBalanceLoading(false);
    }
  };

  useEffect(() => {
    fetchBalance();
    getAllPhoneNumbers();
  }, [user?.secret_key]);

  const getAllPhoneNumbers = async () => {
    try {
      const response = await axios.get(
        `${url}/transaction/withdraw/all-phone/${user?.id}`
      );
      // console.log(response?.data);
      setAllPhoneNumbers(response?.data);
      const numbers = response?.data.map(
        (withdraw: WithdrawType) => withdraw?.withdraw_number || ""
      );
      const uniqueNumbers = [...new Set(numbers)];
      setFilteredNumbers(uniqueNumbers as string[]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWithdraws = async (data?: string) => {
    if (!user) return;

    try {
      if (data === "refetch") {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
      const getWithdraws = await axios.get(
        `${url}/transaction/withdraw?id=${user.id}&&page=${currentPage}&&limit=${itemsPerPage}`
      );
      setWithdraws(getWithdraws?.data?.withdraws);
      setTotalItems(getWithdraws?.data?.totalItems);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdraws();
  }, [user, currentPage]);

  const refetchWithdraws = (data: WithdrawType[]) => {
    const lastTenData = [...data].reverse().slice(-10);
    const isPending = lastTenData.filter(
      (withdraw) => withdraw.status.toLowerCase() === "Pending".toLowerCase()
    );
    if (isPending?.length > 0 || enabled) {
      fetchWithdraws("refetch");
    }
  };

  useEffect(() => {
    setEnableSeconds(enabledInterval! * 1000);
  }, [enabledInterval]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        refetchWithdraws(withdraws);
      },
      enabled ? enableSeconds! : 2000
    );

    return () => clearInterval(interval);
  }, [withdraws, enabled, enableSeconds]);

  const handleConfirmReject = async () => {
    if (selectedWithdraw) {
      try {
        await axios.put(`${url}/transaction/withdraw/reject`, {
          payment_id: selectedWithdraw,
          secret_key: user?.secret_key,
          payment_api_link: user?.payment_api_link,
        });

        toast.success("Withdrawal Cancelled.");

        await fetchWithdraws();
      } catch (error) {
        toast.error("Failed to Cancelled withdrawal.");
      }
      closeModal();
    }
  };

  const handleWithdraw = async (e: React.FormEvent) => {
    e.preventDefault();
    setWithdrawLoading(true);

    setErrors({
      method: "",
      number: "",
      amount: "",
    });

    let hasError = false;

    if (!withdrawMethod) {
      setErrors((prev) => ({
        ...prev,
        method: "Please select a valid withdrawal method",
      }));
      hasError = true;
    }

    if (!withdrawNumber) {
      setErrors((prev) => ({
        ...prev,
        number: "Please enter a withdrawal number",
      }));
      hasError = true;
    } else if (withdrawNumber.length < 11 || !/^\d+$/.test(withdrawNumber)) {
      setErrors((prev) => ({
        ...prev,
        number: "Please enter a valid withdrawal number",
      }));
      hasError = true;
    }

    if (!withdrawAmount || Number(withdrawAmount) <= 0) {
      setErrors((prev) => ({
        ...prev,
        amount: "Please enter a valid withdrawal amount",
      }));
      hasError = true;
    }

    if (Number(withdrawAmount) > balance) {
      setErrors((prev) => ({
        ...prev,
        amount: "Insufficient balance",
      }));
      hasError = true;
      toast.error("Insufficient balance");
    }

    if (Number(withdrawAmount) < 2) {
      setErrors((prev) => ({
        ...prev,
        amount: "Minimum withdrawal amount is 2",
      }));
      hasError = true;
    }

    if (hasError) {
      setWithdrawLoading(false);
      return;
    }

    if (!user?.public_key || !user?.secret_key || !user?.payment_api_link) {
      toast.error("Please update your payment information first.");
      setWithdrawLoading(false);
      navigate("/keys");
    }

    try {
      await axios.post(`${url}/transaction/withdraw`, {
        amount: Number(withdrawAmount),
        userId: user?.id,
        public_key: user?.public_key,
        secret_key: user?.secret_key,
        payment_api_link: user?.payment_api_link,
        payment_method: withdrawMethod,
        withdraw_number: withdrawNumber,
      });

      setWithdrawLoading(false);
      setWithdrawAmount("");
      setWithdrawNumber("");
      // setWithdrawMethod("");

      toast.success("Withdrawal is on process. Please wait for confirmation.");

      await fetchWithdraws("refetch");
      fetchBalance();
      getAllPhoneNumbers();
    } catch (error) {
      setWithdrawLoading(false);
      toast.error("Failed to withdraw. Check your number , amount and method.");
    }
  };

  const handleWithdrawNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    errors.number = "";
    const value = e.target.value;
    // Only allow digits and limit to 11 characters
    if (/^\d*$/.test(value) && value.length <= 11) {
      setWithdrawNumber(value);

      // Filter matching numbers from withdraws and remove duplicates
      if (value) {
        const matches = [
          ...new Set(
            allPhoneNumebers
              .map((withdraw) => withdraw?.withdraw_number || "")
              .filter((num) => num?.includes(value))
          ),
        ];
        setFilteredNumbers(matches);
        setShowNumberSuggestions(true);
      } else {
        //show all numbers
        // {withdraws?.map((withdraw: WithdrawType) => () => withdraw?.withdraw_number || "")}

        const numbers = allPhoneNumebers.map(
          (withdraw: WithdrawType) => withdraw?.withdraw_number || ""
        );
        const uniqueNumbers = [...new Set(numbers)];
        setFilteredNumbers(uniqueNumbers as string[]);
        setShowNumberSuggestions(false);
      }
    }
  };

  const numRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (numRef.current && !numRef.current.contains(event.target as Node)) {
        setShowNumberSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectNumber = (number: string) => {
    setWithdrawNumber(number);
    console.log("number", number);
    setShowNumberSuggestions(false);

    const numbers = allPhoneNumebers.map(
      (withdraw: WithdrawType) => withdraw?.withdraw_number || ""
    );
    const uniqueNumbers = [...new Set(numbers)];
    setFilteredNumbers(uniqueNumbers as string[]);
  };

  const handleWithdrawAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    errors.amount = "";
    const value = e.target.value;
    // Only allow digits and decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setWithdrawAmount(value);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy to clipboard!");
      });
  };

  const TableSkeleton = () => (
    <tbody>
      {[...Array(5)].map((_, index) => (
        <tr
          key={index}
          className="border-b border-opacity-20 border-gray-700 bg-gray-900"
        >
          {/* Date & Time */}
          <td className="p-3 whitespace-nowrap">
            <div className="h-4 w-[80px] bg-gray-700 rounded animate-pulse mb-1"></div>
            <div className="h-4 w-[60px] bg-gray-700 rounded animate-pulse"></div>
          </td>

          {/* Payout Number */}
          <td className="p-3 whitespace-nowrap">
            <div className="h-4 w-[100px] bg-gray-700 rounded animate-pulse mb-1"></div>
            <div className="h-4 w-[80px] bg-gray-700 rounded animate-pulse"></div>
            <div className="h-4 w-[90px] bg-gray-700 rounded animate-pulse"></div>
          </td>

          {/* Payment Info */}
          <td className="p-3 whitespace-nowrap">
            <div className="h-4 w-[120px] bg-gray-700 rounded animate-pulse mb-1"></div>
            <div className="h-4 w-[100px] bg-gray-700 rounded animate-pulse mb-1"></div>
            <div className="h-4 w-[140px] bg-gray-700 rounded animate-pulse"></div>
          </td>

          {/* Reason */}
          <td className="p-3 whitespace-wrap">
            <div className="h-4 w-[180px] bg-gray-700 rounded animate-pulse"></div>
          </td>

          {/* Status */}
          <td className="p-3 whitespace-nowrap">
            <div className="h-6 w-[80px] bg-gray-700 rounded-lg animate-pulse"></div>
          </td>

          {/* Action */}
          <td className="p-3 cursor-pointer">
            <div className="flex items-center justify-center gap-4">
              <div className="w-6 h-6 bg-gray-700 rounded animate-pulse"></div>
              <div className="w-6 h-6 bg-gray-700 rounded animate-pulse"></div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const [modal, setModal] = useState<WithdrawType | null>(null);

  console.log("modal", modal);

  return (
    <section className=" min-h-[85vh] custom_container pt-3 pb-10">
      {modal && (
        <ActivityDetails modal={{ ...modal, createdAt: modal?.createdAt?.toString() }} setModal={() => setModal(null)} />
      )}

      {/* <h1 className="text-3xl font-bold text-left mb-5">Withdraws</h1> */}

      <div className="flex gap-2 items-center">
        <h1 className="text-2xl font-semibold">Balance:</h1>
        {isBalanceLoading ? (
          <div className="h-7 w-32 bg-gray-700 rounded animate-pulse"></div>
        ) : (
          <p className="text-xl font-bold text-red-500">BDT {balance}</p>
        )}
      </div>

      <div className=" mt-5">
        <form
          onSubmit={handleWithdraw}
          className="grid grid-cols-2 w-full md:w-[70vw] lg:w-[60vw] xl:w-[40vw]   gap-5 "
        >
          <div className="flex flex-col gap-1">
            <select
              name="payment_method"
              id="payment_method"
              className={`py-2 px-3 border rounded-md ${
                errors.method ? "border-red-500" : "border-gray-700"
              }`}
              onChange={(e) => {
                errors.method = "";
                setWithdrawMethod(e.target.value);
              }}
            >
              <option value="">Payment Method</option>
              <option value="bkash">Bkash</option>
              <option value="nagad">Nagad</option>
            </select>
            {errors.method && (
              <p className="text-red-500 text-sm">{errors.method}</p>
            )}
          </div>

          <div className="flex flex-col gap-1 relative">
            <input
              type="text"
              placeholder="Withdraw Number"
              value={withdrawNumber}
              onChange={handleWithdrawNumberChange}
              onClick={() => setShowNumberSuggestions(true)}
              className={`py-2 px-3 border rounded-md ${
                errors.number ? "border-red-500" : "border-gray-700"
              }`}
            />
            {errors.number && (
              <p className="text-red-500 text-sm">{errors.number}</p>
            )}

            {showNumberSuggestions && filteredNumbers.length > 0 && (
              <div
                ref={numRef}
                className="absolute top-full left-0 right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-40 overflow-y-auto z-10"
              >
                {filteredNumbers.map((number, index) => (
                  <button
                    type="button"
                    key={index}
                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSelectNumber(number)}
                  >
                    {number}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <input
              type="text"
              placeholder="Withdraw Amount"
              value={withdrawAmount}
              onChange={handleWithdrawAmountChange}
              className={`py-2 px-3 border rounded-md ${
                errors.amount ? "border-red-500" : "border-gray-700"
              }`}
            />
            {errors.amount && (
              <p className="text-red-500 text-sm">{errors.amount}</p>
            )}
          </div>

          <button
            type="submit"
            className="px-3 py-2 h-11 bg-orange-500 hover:bg-orange-600 active:bg-[#ee5b41] border-none rounded-md text-white flex items-center justify-center"
            disabled={withdrawLoading}
          >
            {withdrawLoading ? <Loading /> : "Withdraw"}
          </button>
        </form>
      </div>

      <div className="flex justify-end">
        <div className="flex items-center gap-2 mt-4">
          <p className="text-gray-700 dark:text-gray-400">Every</p>
          <input
            defaultValue={1}
            onChange={(e) => setEnabledInterval(Number(e.target.value))}
            type="number"
            className="w-16 h-8 border border-gray-300 dark:border-gray-600 bg-transparent rounded-md text-center text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
          />
          <p className="text-gray-700 dark:text-gray-400">sec</p>
          <button
            onClick={() => setEnabled(!enabled)}
            className={`relative p-1 w-14 h-7  flex items-center rounded-full transition-colors duration-300 ${
              enabled ? "bg-blue-500" : "bg-gray-400"
            }`}
          >
            <div
              className={`w-6 h-6 bg-white  rounded-full shadow-md transform transition-transform duration-300 ${
                enabled ? "translate-x-[25px]" : "translate-x-0"
              }`}
            />
          </button>
        </div>
      </div>
      <div className="mx-auto text-gray-100 mt-2 w-full">
        <div className="overflow-x-auto rounded-md w-full">
          <table className="w-full rounded-md table-fixed text-[16px] ">
            <thead className="bg-gray-600 ">
              <tr className="text-left ">
                <th className="p-3 w-[100px]">Date & Time</th>
                <th className="p-3 w-[150px]">Payout Number</th>
                <th className="p-3 w-[200px] lg:w-[200px]">Payment Info</th>
                <th className="p-3 w-[250px]">Reason</th>
                <th className="p-3 w-[100px]">Status</th>
                <th className="p-3 w-[150px] lg:w-[100px]">Action</th>
              </tr>
            </thead>
            {isLoading ? (
              <TableSkeleton />
            ) : withdraws?.length > 0 ? (
              <tbody>
                {withdraws?.map((withdraw: WithdrawType) => (
                  <tr
                    key={withdraw?.id}
                    className="border-b border-opacity-20 border-gray-700 bg-gray-900 "
                  >
                    <td className="p-3 whitespace-nowrap">
                      <p>
                        {withdraw?.createdAt
                          ? moment(withdraw.createdAt).format("MMM D, YYYY")
                          : ""}
                      </p>
                      <p>{moment(withdraw?.createdAt).format("h:mm A")}</p>
                    </td>

                    <td className="p-3 whitespace-nowrap">
                      <p className="capitalize">{withdraw?.payment_method}</p>
                      <p>Amount: {withdraw?.amount}</p>
                      <p
                        className="cursor-pointer hover:text-blue-400 transition-colors"
                        onClick={() =>
                          copyToClipboard(withdraw?.withdraw_number)
                        }
                        title="Click to copy"
                      >
                        {withdraw?.withdraw_number}
                      </p>
                    </td>

                    <td className="p-3 whitespace-nowrap">
                      <p>
                        Trx:{" "}
                        <span
                          onClick={() => copyToClipboard(withdraw?.trxId)}
                          className="cursor-pointer hover:text-blue-500"
                        >
                          {withdraw?.trxId}
                        </span>
                      </p>
                      <p>
                        Ref:{" "}
                        <span
                          onClick={() => copyToClipboard(withdraw?.payment_id)}
                          className="cursor-pointer hover:text-blue-500"
                        >
                          {withdraw?.payment_id}
                        </span>{" "}
                      </p>
                    </td>

                    <td className="p-3 whitespace-wrap">
                      <p>{withdraw?.reason}</p>
                    </td>

                    <td className="p-3 whitespace-nowrap">
                      <p
                        className={`text-center px-2 w-24 py-2 rounded-md  ${
                          withdraw?.status.toLowerCase().includes("pend")
                            ? "bg-yellow-500"
                            : withdraw?.status.toLowerCase().includes("success")
                            ? "bg-green-500"
                            : withdraw?.status.toLowerCase().includes("reject")
                            ? "bg-red-500"
                            : withdraw?.status.toLowerCase().includes("await")
                            ? "bg-orange-500"
                            : withdraw?.status.toLowerCase().includes("fail")
                            ? "bg-red-700"
                            : "bg-blue-500"
                        }`}
                      >
                        {withdraw?.status.charAt(0).toUpperCase() +
                          withdraw?.status.slice(1).toLowerCase()}
                      </p>
                    </td>

                    <td className="p-3 cursor-pointer items-center h-auto">
                      <div className="flex items-center gap-2  justify-center">
                        {withdraw?.status.toLowerCase().includes("pend") ? (
                          <button
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => openModal(withdraw)}
                          >
                            <img src="/cross.png" alt="reject" />
                          </button>
                        ) : (
                          <button
                            className="w-6 h-6 cursor-not-allowed opacity-45"
                            disabled
                          >
                            <img src="/cross.png" alt="reject" />
                          </button>
                        )}
                        {withdraw?.callbacks?.length ? (
                          <button
                            onClick={() =>
                              setModal(withdraw)
                            }
                            className=" ml-5 bg-blue-500 rounded flex items-center justify-center p-2 cursor-pointer hover:bg-blue-600 active:bg-blue-700 opacity-95"
                          >
                            <FaEye />
                          </button>
                        ) : (
                          <button
                            className=" ml-5 bg-blue-500 rounded flex items-center justify-center p-2 cursor-not-allowed opacity-50"
                            disabled
                          >
                            <FaEye />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr className="border-b border-opacity-20 border-gray-700 bg-gray-900">
                  <td className="p-3 whitespace-nowrap" colSpan={6}>
                    <p className="text-center">No Withdraws Found</p>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      {!isLoading && totalItems >= 7 && <Pagination />}

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-20"></div>
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="bg-gray-800 px-4 py-3 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-white">
                Are you sure you want to Cancel this withdrawal?
              </h3>
            </div>
            <div className="bg-gray-900 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleConfirmReject}
              >
                Yes, Cancel
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Withdraw;
